<template>
    <b-container fluid>
        <!-- User Interface controls -->
        <b-row>
            <b-col md="4" class="my-1 ml-0">
              <b-form v-if="filterMode === 'audit' || filterMode === 'auditMto'" @submit.prevent="queryData()">
                <b-input-group>
                  <b-input v-model="metricData.startDate" type="date" name=""/>
                  <b-input v-model="metricData.endDate" type="date" name=""/>
                  <b-button type="submit">Query</b-button>
                </b-input-group>
              </b-form>
            </b-col>
            <b-col sm="4">
            </b-col>
            <b-col v-if="filterMode !== 'accountingRuleEntries'" md="4" class="my-1">
                <b-form-group
                        :label="showTitle?'Filter':''"
                        label-cols-sm="2"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <br>

        <!-- Main table element -->
        <b-table
                small
                show-empty
                stacked="md"
                style="font-size: 12px;"
                striped
                :items="items"
                :busy="isBusy"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :tbody-tr-class="rowClass"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
        >

            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

            <!-- TODO AMOUNT FORMATTING -->
                <template v-slot:cell(bookBalance)="row">
                    {{ row.item.bookBalance | formatAmount }}
                </template>
                <template v-slot:cell(transSourceAmount)="row">
                    {{ row.item.transSourceAmount | formatAmount }}
                </template>
                <template v-slot:cell(transDestinationAmount)="row">
                    {{ row.item.transDestinationAmount | formatAmount }}
                </template>
                <template v-slot:cell(roleTrnLimit)="row">
                    {{ row.item.roleTrnLimit | formatAmount }}
                </template>
                <template v-slot:cell(parameterCashLimit)="row">
                    {{ row.item.parameterCashLimit | formatAmount }}
                </template>
                <template v-slot:cell(parameterIntraDcLimit)="row">
                    {{ row.item.parameterIntraDcLimit | formatAmount }}
                </template>
                <template v-slot:cell(parameterInterDcLimit)="row">
                    {{ row.item.parameterInterDcLimit | formatAmount }}
                </template>
                <template v-slot:cell(transactionAmount)="row">
                    {{ row.item.transactionAmount | formatAmount }}
                </template>
                <template v-slot:cell(transactionDestinationAmount)="row">
                    {{ row.item.transactionDestinationAmount | formatAmount }}
                </template>
                <template v-slot:cell(availableBalance)="row">
                    {{ row.item.availableBalance | formatAmount }}
                </template>
                <template v-slot:cell(entryAmount)="row">
                    {{ row.item.entryAmountType === 'PERCENT'?row.item.entryAmount+'%' : row.item.entryAmount }}
                </template>
            <!-- / -->

            <!-- TODO DATE FORMATTING -->
                <template v-slot:cell(createdat)="row">
                    {{ row.item.createdat | moment("DD-MMM-YYYY") }}
                </template>
            <!-- / -->

            <!-- TODO TEXT ELLIPSIS FORMATTING -->
                <template v-slot:cell(proprietorFirstName)="row">
                    {{ row.item.proprietorFirstName | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(transactionReceiverName)="row">
                    {{ row.item.transactionReceiverFirstName + ' ' + row.item.transactionReceiverLastName | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(proprietorLastName)="row">
                    {{ row.item.proprietorLastName | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(businessName)="row">
                    {{ row.item.businessName | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(proprietorAddress)="row">
                    {{ row.item.proprietorAddress | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(businessType)="row">
                    {{ row.item.businessType | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(transSender)="row">
                    {{ row.item.transSender | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(transReceiver)="row">
                    {{ row.item.transReceiver | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(screeningDescription)="row">
                    {{ row.item.screeningDescription | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(proprietorEmail)="row">
                    {{ row.item.proprietorEmail.toUpperCase() | formatTextWithEllipsis }}
                </template>
                <template v-slot:cell(transactionStatus)="row">
                    {{ row.item.transactionStatus | titleCase }}
                </template>
            <!-- / -->

            <!-- formatting bankcode-->
                <template v-slot:cell(destinationbankcode)="row" :title="row.item.authorizers">
                    {{ getBankName(row.item.destinationbankcode, ft.originalBankList) }}
                </template>
            <!-- / -->

            <template v-slot:cell(serial)="row">
                {{ row.index + 1 }}
            </template>

            <template v-slot:cell(actions)="row">
              <a v-if="row.detailsShowing" href="#" @click="row.toggleDetails">Less</a>
              <a v-else href="#" @click="row.toggleDetails">More</a>
<!--                <b-button size="xs" @click="row.toggleDetails">-->
<!--                    {{ row.detailsShowing ? 'Less' : 'More' }}-->
<!--                </b-button>-->
            </template>

            <template v-slot:cell(actionsUser)="row">
              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />
              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />
              <b-icon-pencil style="cursor: pointer; width: 30px; height: 20px;" href="#"  @click="showModal(row.item.userId, 'user', row.item)" />
            </template>

            <template v-slot:cell(actionsMtoUser)="row">
              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />
              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />
              <b-icon-pencil v-if="!auth.userInfo.username.includes('@')" style="cursor: pointer; width: 30px; height: 20px;" href="#"  @click="showModal(row.item.userId, 'mtoUser', row.item)" />
            </template>

            <template v-slot:cell(actionsRole)="row">
              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />
              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />
              <b-icon-pencil style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="navigate(row.item.roleId, 'rolePrivilege')" />
            </template>

            <template v-slot:cell(actionsParameter)="row">
              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />
              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />
              <b-icon-pencil style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="showModal(row.item.parameterId, 'parameterization', row.item)" />
            </template>

            <template v-slot:cell(actionsMto)="row">
              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />
              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />
              <b-icon-justify style="cursor: pointer; width: 30px; height: 20px;"  href="#" @click="navigate(row.item.mtoCode, 'imtoUsers')" />
              <b-icon-pencil style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="showModal(row.item.mtoCode, 'mtoAction', row.item)" />
            </template>

            <template v-slot:cell(actionsChecker)="row">
<!--              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />-->
<!--              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />-->
              <b-icon-info-circle style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="showModal(row.item.cqId, 'checker', row.item)" />
            </template>

            <template v-slot:cell(actionsMaker)="row">
<!--              <b-icon-eye-slash style="cursor: pointer; width: 30px; height: 20px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails" />-->
<!--              <b-icon-eye style="cursor: pointer; width: 30px; height: 20px;" v-else href="#" @click="row.toggleDetails" />-->
              <b-icon-info-circle style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="showModal(row.item.cqId, 'maker', row.item)" />
            </template>

            <template v-slot:cell(actionsTransactionDc)="row">
              <a style="padding-right: 0px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>
              <a style="padding-right: 0px;" v-else href="#" @click="row.toggleDetails">more | </a>
              <a href="#" @click="showReviewModal(row.item, 'DC')">review</a>
            </template>

            <template v-slot:cell(actionsTransactionCp)="row">
              <a style="padding-right: 0px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>
              <a style="padding-right: 0px;" v-else href="#" @click="row.toggleDetails">more | </a>
              <a href="#" @click="showReviewModal(row.item, 'CP')">review</a>
            </template>

            <template v-slot:cell(actionsTransactionPending)="row">
              <a style="padding-right: 0px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>
              <a style="padding-right: 0px;" v-else href="#" @click="row.toggleDetails">more | </a>
              <a href="#" @click="showReviewModal(row.item, row.item.contractType)">review</a>
            </template>

            <template v-slot:cell(actionsAccountRule)="row">
              <a style="padding-right: 0;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>
              <a style="padding-right: 0;" v-else href="#" @click="row.toggleDetails">more | </a>
              <a href="#" @click="showModal(row.item.aeCode, 'accountingRule', row.item)">entries | </a>
              <a href="#" style="color: red;" @click="updateStatus(row.item.aeCode, 'DELETED', 'accountingRule',row.item)"><b-icon-trash/>delete</a>
            </template>

            <template v-slot:cell(actionsScreening)="row">
              <a style="padding-right: 0;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>
              <a style="padding-right: 0;" v-else href="#" @click="row.toggleDetails">more | </a>
              <a href="#" style="color: red;" @click="updateStatus(row.item.screeningCode, 'DELETED', 'screening',row.item)"><b-icon-trash/>delete</a>
            </template>

            <template v-slot:cell(actionsReport)="row">
              <b-button @click="queryReport(row.item.reportName)" title="Query Report" size="xs" >
                <b-icon-justify/>
              </b-button> &nbsp;
              <b-button @click="updateStatus(row.item.reportName, 'DELETED', 'report',row.item)" title="Query Report" size="xs" >
                <b-icon-trash/>
              </b-button> &nbsp;
              <b-button @click="showReportSchedulerModal = !showReportSchedulerModal" title="Query Report" size="xs" >
                <b-icon-clock/>
              </b-button> &nbsp;
              <b-button size="xs" @click="row.toggleDetails">
                {{ row.detailsShowing ? 'Less' : 'More' }}
              </b-button>
            </template>

            <template v-slot:cell(actionsLoan)="row">
                <b-button size="xs" @click="row.toggleDetails">
                    {{ row.detailsShowing ? 'Less' : 'More' }}
                </b-button>
                <b-button title="Loan Schedule" variant="success" size="xs" @click="navigate(row.item.loanId, 'rolePrivilege')">
                    <b-icon-justify/>
                </b-button>
            </template>

            <template v-slot:cell(actionsAccountingRuleEntries)="row">
<!--              <a style="padding-right: 0px;" v-if="row.detailsShowing" href="#" @click="row.toggleDetails">less | </a>-->
<!--              <a style="padding-right: 0px;" v-else href="#" @click="row.toggleDetails">more | </a>-->
              <b-icon-trash style="cursor: pointer; width: 30px; height: 20px;" href="#" @click="deleteItem(row.item.entryId, 'entries')"/>
            </template>

            <template v-slot:row-details="row">
                <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key"><b>{{ key | camelToSentenceCase}}</b>: {{ value }}</li>
                    </ul>
<!--                    <sub-base-table-->
<!--                            :items="[]"-->
<!--                            :fields="subfields"-->
<!--                            :show-title="true"-->
<!--                            :is-busy="false"-->
<!--                            class="mt-2"-->
<!--                    ></sub-base-table>-->
                </b-card>
            </template>
        </b-table>

        <b-row>
            <b-col v-if="filterMode !== 'accountingRuleEntries'" md="3" class="my-1 ml-0">
                <b-form-group
                        :label="showTitle?'Per page':'Page'"
                        label-cols-sm="6"
                        label-cols-md="5"
                        label-cols-lg="4"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="perPage"
                            id="perPageSelect"
                            size="sm"
                            :options="pageOptions"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="4"></b-col>
            <b-col v-if="filterMode !== 'accountingRuleEntries'" sm="5" md="4" class="my-1 mr-0 mx-3">
                <b-pagination
                        v-model="currentPage"
                        :total-rows="items==null?0:items.length"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
      <review-modal :show="showReview" :row="reviewModel" :type="reviewType"/>
      <checker-modal :show="showCheckerModal" :row="row"/>
      <accounting-rule-modal :show="showAccountingRuleModal" :row="row"/>
      <parameterization-modal :show="showParameterizationModal" :row="row"/>
      <create-mto-user-modal :show="showUserModal" :row="row" module="user" />
      <create-mto-user-modal :show="showMtoUserModal" :row="row" module="mto" />
      <partner-modal :show="showMtoModal" :row="row"/>
      <report-scheduler-modal :show="showReportSchedulerModal" :row="row"/>
    </b-container>
</template>

<script>
    import {mapState} from "vuex";
    import format from 'date-fns/format'
    import Swal from 'sweetalert2';
    import ReviewModal from "@/components/modal/ReviewModal";
    import CheckerModal from "@/components/modal/CheckerModal";
    import AccountingRuleModal from "@/components/modal/AccountingRuleModal";
    import ParameterizationModal from "@/components/modal/ParameterizationModal";
    import CreateMtoUserModal from "@/components/modal/CreateMtoUserModal";
    import PartnerModal from "@/components/modal/PartnerModal";
    import ReportSchedulerModal from "@/components/modal/ReportSchedulerModal";
    // import BaseButton from "@/components/button/AuthBaseButton";
    // import {fbnCoreBaseUrlV1} from '../../app.config'
    // import SubBaseTable from "./SubBaseTable";
    // import {MoreHorizontal, Delete} from 'vue-feather-icon'
    // import Vue from "vue";
    export default {
        props:['items', 'fields', 'subfields', 'isBusy','showTitle', 'type','showMetrics','filterMode','accounts'],
        components:{
          ReportSchedulerModal,
          // BaseButton,
          AccountingRuleModal,
          ReviewModal,
          ParameterizationModal,
          CreateMtoUserModal,
          PartnerModal,
          CheckerModal
        },
        name: 'BaseTable',
        data() {
            return {
                reviewType:'',
                show:false,
                showMtoModal:false,
                showReportSchedulerModal:false,
                showCheckerModal:false,
                showUserModal:false,
                showMtoUserModal:false,
                showAccountingRuleModal:false,
                showParameterizationModal:false,
                reviewModel:{},
                dateFormat: 'D MMM',
                metricData:{
                    type:'custom',
                    startDate:'',
                    endDate:''
                },
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [20, 30, 50, 100],
                sortBy: '',
                showReview: true,
                showContract: true,
                row: {},
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                showInvoice: true,
                showDD: true,
                showBill: true,
                model:{
                    countryId: '',
                }

            }
        },
        computed: {
            ...mapState([
                'auth'
            ]),
        },
        mounted() {
            // Set the initial number of items
            // this.totalRows = this.items.length
        },
        methods: {
          queryData(){
            if (this.filterMode === 'audit') {
              this.$store.commit("user/updateUserLoading", true)
              this.$store.dispatch("user/updateAuditTrails", this.metricData, {root: false}).then()
            }else if (this.filterMode === 'auditMto'){
              this.$store.commit("user/updateUserLoading", true)
              this.$store.dispatch("user/updateAuditTrailsMto", this.metricData, {root: false}).then()
            } //metricData

          },
          deleteItem(_id, _type){
            if (_type === 'entries') {
              console.log(_id)
              this.$store.commit('accountingRule/removeEntryFromSlab', _id)
            }
          },
            queryReport(_reportName){
              localStorage.reportName = _reportName;
              this.$store.dispatch('report/updateReportSingle', {
                reportBankCode: "000000",
                reportName:_reportName,
                reportFieldList:"",
                reportCondition:"",
                readCount:"ALL"
              });
              this.$router.push('/report-engine-single')
            },
            showReviewModal(_row, _type){
               this.reviewModel = _row
               this.reviewType = _type
               this.showReview = !this.showReview
            },
            showModal(_id, _type, _row = {}){
              //localStorage._counterparty = _id;
              if (_type === 'gradeSalary'){
                this.row = _row
                console.log(this.row)
                this.showGradeSalary = !this.showGradeSalary
              }
              if (_type === 'checker'){
                this.row = _row
                console.log(this.row)
                this.showCheckerModal = !this.showCheckerModal
              }
              if (_type === 'maker'){
                this.row = _row
                this.row.isMaker = true
                console.log(this.row)
                this.showCheckerModal = !this.showCheckerModal
              }
              if (_type === 'accountingRule'){
                this.row = _row
                console.log(this.row)
                this.showAccountingRuleModal = !this.showAccountingRuleModal
              }
              if (_type === 'parameterization'){
                this.row = _row
                this.row.type = 'edit'
                console.log(this.row)
                this.showParameterizationModal = !this.showParameterizationModal
              }
              if (_type === 'user'){
                this.row = _row
                this.row.type = 'edit'
                console.log(this.row)
                this.showUserModal = !this.showUserModal
              }
              if (_type === 'mtoUser'){
                this.row = _row
                this.row.type = 'edit'
                console.log(this.row)
                this.showMtoUserModal = !this.showMtoUserModal
              }
              if (_type === 'mtoAction'){
                this.row = _row
                this.row.type = 'edit'
                console.log(this.row)
                this.showMtoModal = !this.showMtoModal
              }
            },
            navigate( _id, _type = 'schedules'){
                if (_type === 'schedules'){
                    localStorage.repaymentLoanId = _id;
                    this.$router.push('/loans/schedules');
                }else if (_type === 'rolePrivilege'){
                  //localStorage.repaymentLoanId = _id;
                  this.$router.push({name: 'CreateRole', params: { roleId: _id}})
                }else if (_type === 'imtoUsers'){
                  localStorage.mtoCode = _id;
                  this.$router.push({name: 'ImtoUsers', params: { mtoCode: _id}})
                }
            },
            disabled(_status){
                let rtn = false
                if (_status == null) return rtn
                return _status.toLowerCase() === 'deleted' ||
                    _status.toLowerCase() === 'approved' ||
                    // _status.toLowerCase() === 'orgapproved' ||
                    _status.toLowerCase() === 'orgdeclined' ||
                    _status.toLowerCase() === 'declined';

            },
            /**
             * @return {boolean}
             */
            OrgDisabled(_status){
                let rtn = false
                if (_status == null) return rtn
                return _status.toLowerCase() === 'deleted' || _status.toLowerCase() === 'orgapproved' || _status.toLowerCase() === 'approved' || _status.toLowerCase() === 'declined';

            },

            onFiltered(data){
                console.log(data)
            },

            async updateStatus(_id, _status, _mode, _row = {}){
                let status = ''
                if (_status.toLowerCase().includes('approve')) status = 'approve';
                if (_status.toLowerCase().includes('decline')) status = 'decline';
                if (_status.toLowerCase().includes('delete')) status = 'delete';
                // if (_status.includes('delete')) status = 'delete';
                let errorMessage = ''
                this.$swal({
                    text: `Are you sure you want to perform this action(${status})?`,
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let payload = {
                            approverid: this.auth.userInfo.userId,
                            contractChecker: this.auth.userInfo.userId,
                            beneficiaryid: _id,
                            orgId: localStorage.orgID,
                            status: _status,
                            contractType: '',
                            contractId: _id,
                            contractStatus: _status,
                            requestType: 'updateStatus',
                            contractCreditAccountNumber: _row.contractCreditAccountNumber,
                            contractDebitAccountNumber: _row.contractDebitAccountNumber,
                        }
                        let endpoint = 'beneficiary/approveBeneficiary'
                        if(_mode === 'db'){
                            payload.contractType = 'DD'//{approverid:this.system.userInfo.id, directdebitid:_id, status:_status}
                            endpoint = 'directDebit/approveContract'
                        }else if (_mode === 'si'){
                            payload.contractType = 'SI' //{approverid:this.system.userInfo.id, standinginstructionid:_id, status:_status}
                            endpoint = 'standingInstruction/approveContract'
                        }else if (_mode === 'accountingRule'){
                            payload = {}
                            payload.aeCode = _id //{approverid:this.system.userInfo.id, standinginstructionid:_id, status:_status}
                            payload.aeMaker = this.auth.userInfo.userId
                            endpoint = 'accountingRule/deleteAccountingRule'
                        }else if (_mode === 'screening'){
                            payload = {}
                            payload.screeningCode = _id
                            payload.screeningMaker = this.auth.userInfo.userId
                            payload.screeningStatus = _status
                            endpoint = 'screening/updateStatusTransactionScreening'
                        }else if (_mode === 'report'){
                            payload = {}
                            payload.reportName = _id
                            payload.reportMaker = this.auth.userInfo.userId
                            payload.reportStatus = _status
                            endpoint = 'report/updateStatus'
                        }
                        console.log(endpoint,payload)
                        return this.$store.dispatch(endpoint, payload)
                        .then(response => {
                            console.log(response.data)
                            if (response.data.responseCode !== '00') {
                                errorMessage = response.data.responseMessage
                                throw new Error(response.data.responseMessage)
                            }
                            return response
                        }).catch(error => {
                                console.log(error)
                                Swal.showValidationMessage(
                                    `Request failed: ${errorMessage}`
                                )
                            })
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    console.log(result.value)
                    if (result.value.data.responseCode === '00') {
                        let payload = {orgID: localStorage.orgID,
                            orgId: localStorage.orgID,
                            contractOrgId:localStorage.orgID,
                            module:'',
                            requestType:'read',contractType:''}
                        let endpoint = 'beneficiary/updateBeneficiaries'
                        if(_mode === 'db'){
                            // payload = {approverid:this.system.userInfo.id, directdebitid:_id, status:_status}
                            endpoint = 'directDebit/updateDirectDebits'
                            payload.module = 'payment';
                            payload.contractType = 'DD';
                            this.$store.dispatch('directDebit/updateDebitDirectDebits', payload , {root: false})
                            payload.module = 'collection';
                            payload.contractType = 'DD';
                        }else if (_mode === 'si'){
                            payload.module = 'payment';
                            payload.contractType = 'SI';
                            // payload = {approverid:this.system.userInfo.id, standinginstructionid:_id, status:_status}
                            endpoint = 'standingInstruction/updateStandingInstructions'
                        }else if (_mode === 'accountingRule'){
                            payload={};
                            payload.aeBankCode = '00000';
                            endpoint = 'accountingRule/updateAccountingRules'
                        }else if (_mode === 'screening'){
                            payload={};
                            payload.readAll = 'YES';
                            endpoint = 'screening/updateTransactionScreenings'
                        }else if (_mode === 'report'){
                            payload={};
                            payload.readAll = 'YES';
                            payload.reportBankCode = '00000';
                            payload.reportCategory = 'GENERAL SERVICES'
                            endpoint = 'report/updateReports'
                        }

                        this.$store.dispatch(endpoint, payload , {root: false})
                        this.$swal(
                            'Success',
                            result.value.data.responseMessage,
                            'success'
                        )
                    }
                })
            },

            getMonday(d) {
                d = new Date(d);
                let day = d.getDay(),
                    diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
                return new Date(d.setDate(diff));
            },

            formatDates(dateOne, dateTwo) {
                let formattedDates = '';
                if (dateOne) {
                    formattedDates = format(dateOne, this.dateFormat)
                }
                if (dateTwo) {
                    formattedDates += ' - ' + format(dateTwo, this.dateFormat)
                }
                return formattedDates
            },

            deleteTran: async function(row){
                // console.log(row)
                this.$swal({
                    text: 'Are you sure you want to delete this transaction?',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        console.log(row)
                        if (row.id != null)
                            await this.$store.dispatch('ft/deleteTransaction',{transactionid: row.id})
                        if (this.filterMode === 'interbankSingle')
                            this.$store.commit('ft/removeInterbankSingle', row);
                        else if (this.filterMode === 'interbankMultiple')
                            this.$store.commit('ft/removeInterbankMultiple', row);
                        else if (this.filterMode === 'intrabankSingle')
                            this.$store.commit('ft/removeIntrabankSingle', row);
                        else if (this.filterMode === 'intrabankMultiple')
                            this.$store.commit('ft/removeIntrabankMultiple', row);
                        else if (this.filterMode === 'ownAccountFt')
                            this.$store.commit('ft/removeOwnAccountFt', row);
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
            },

            rowClass(item, type) {
                if (!item || type !== 'row') return '';
                if (item.cqStatus === 'REJECTED') return 'table-danger';
                else if(item.cqStatus === 'AUTHORIZED') return 'table-success';
                else if(item.entryDrCr === 'CR') return 'table-success';
                else if(item.entryDrCr === 'DR') return 'table-danger';
                else return '';
            }
        }
    }
</script>
