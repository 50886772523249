import QueueService from "../../service/QueueService";
import swal from "sweetalert";
// import swal from 'sweetalert'
//import router from "../../router";



export const state = {
    queues:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    makerQueues:{
        responseCode: '',
        responseMessage: '',
        data: []
    },
    queueLoading: false,
    response:{}

}

export const mutations = {
    updateQueues: (state, payload) =>{
        state.queues = payload
    },
    updateMakerQueues: (state, payload) =>{
        state.makerQueues = payload
    },
    updateQueueLoading: (state, payload) =>{
        state.queueLoading = payload
    },
    updateResponse: (state, payload) =>{
        state.response = payload
    },
}

export const actions = {
    updateQueues: ({ commit, state }, payload)=>{
        if(state.queues.data.length < 1)
            commit("updateQueueLoading", true)
        return QueueService.callReadCheckerQueueApi(payload)
            .then(response => {
                commit("updateQueueLoading", false)
                commit("updateResponse", response.data)
                if (response.data.responseCode === "00") {
                    commit("updateQueueLoading", false)
                    let responseData = response.data;
                    commit("updateQueues", responseData)
                }

            })
            .catch(error => {
                commit("updateQueueLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateMakerQueues: ({ commit, state }, payload)=>{
        if(state.makerQueues.data.length < 1)
            commit("updateQueueLoading", true)
        return QueueService.callReadCheckerQueueApi(payload)
            .then(response => {
                commit("updateQueueLoading", false)
                commit("updateResponse", response.data)
                if (response.data.responseCode === "00") {
                    commit("updateQueueLoading", false)
                    let responseData = response.data;
                    commit("updateMakerQueues", responseData)
                }

            })
            .catch(error => {
                commit("updateQueueLoading", false)
                alert(error)
                // throw error;
            });
    },

    authorizeQueue: ({ commit }, payload)=>{
        commit("updateQueueLoading", true)
        return QueueService.callAuthorizeCheckerQueueApi(payload)
            .then(response => {
                commit("updateQueueLoading", false)
                let responseData = response.data
                commit("updateResponse", response.data)
                if (responseData.responseCode === "00") {
                    swal('Success!',responseData.responseMessage,'success').then();
                }else
                    swal('Error!',responseData.responseMessage,'error').then();

            })
            .catch(error => {
                commit("updateQueueLoading", false)
                alert(error)
                // throw error;
            });
    },
}