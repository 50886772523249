<template>
    <div>
        <hr class="mg-t-10 mg-b-30">
        <div class="row">
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-building/></div>
                        <h5 style="cursor: pointer;" @click="navigate('/outbound-transactions')">Branch Operation
                        </h5>
                        <p class="tx-color-03 mg-b-0">Cash Pick Up.</p>
                        <p class="tx-color-03 mg-b-0">Transaction Enquiry</p>
                        <p class="tx-color-03 mg-b-0">Transaction History</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/outbound-transactions')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-people/>
                        </div>
                        <h5 style="cursor: pointer;" @click="navigate('/users')">
                          User Management
                            </h5>
                        <p class="tx-color-03 mg-b-0">User Creation</p>
                        <p class="tx-color-03 mg-b-0">Role Management</p>
                        <p class="tx-color-03 mg-b-0">Role Assignment</p>


                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/users')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-door-open/></div>
                        <h5 style="cursor: pointer;" @click="navigate('/gateway')">
                            Analytics
                            </h5>
                        <p class="tx-color-03 mg-b-0">Dashboards</p>
                        <p class="tx-color-03 mg-b-0">Analytics</p>
                        <p class="tx-color-03 mg-b-0">Transactions</p>

                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/gateway')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-graph-up/></div>
                        <h5 style="cursor: pointer;" @click="navigate('/inbound-transactions')">
                          Transactions
                            </h5>
                        <p class="tx-color-03 mg-b-0">Inbound </p>
                        <p class="tx-color-03 mg-b-0">Outbound</p>
                        <p class="tx-color-03 mg-b-0">Analytics</p>

                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/proprietors')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
        </div><!-- row -->
        <br>
        <div class="row">
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-file-ruled/></div>
                        <h5 style="cursor: pointer;" @click="navigate('/transactions')">AML & Screening
                        </h5>
                        <p class="tx-color-03 mg-b-0">Transaction Queue.</p>
                        <p class="tx-color-03 mg-b-0">Transaction Rule</p>
                        <p class="tx-color-03 mg-b-0">Accounting Rule</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/branch-operation')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-receipt/>
                        </div>
                        <h5 style="cursor: pointer;" @click="navigate('/bank-orphans')">
                          Reconciliations
                            </h5>
                        <p class="tx-color-03 mg-b-0">Bank Orphans</p>
                        <p class="tx-color-03 mg-b-0">MTO Orphans</p>
                        <p class="tx-color-03 mg-b-0">Total Orphans</p>


                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/bank-orphans')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-terminal/></div>
                        <h5 style="cursor: pointer;" @click="navigate('/report-engine')">
                            Report Engine
                            </h5>
                        <p class="tx-color-03 mg-b-0">Dashboard and Analytics </p>
                        <p class="tx-color-03 mg-b-0">Dynamic Reporting</p>
                        <p class="tx-color-03 mg-b-0">Parameterized and Custom Reporting</p>

                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span>Open Module</span>
                        <p @click = "navigate('/report-engine')" style="cursor: pointer;" class="tx-18 lh-0"><b-icon-arrow-right/></p>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
            <div class="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
                <div class="card card-help"  style="border-width: thick;">
                    <div class="card-body tx-13">
                        <div class="tx-60 lh-0 mg-b-25"><b-icon-file-diff/></div>
                        <h5><a href="#" class="link-01">H2H</a>
                        </h5>
                        <p class="tx-color-03 mg-b-0">Host to Host</p>
                        <p class="tx-color-03 mg-b-0">Secure Protocol</p>
                        <p class="tx-color-03 mg-b-0">Data source</p>
                    </div><!-- card-body -->
                    <div class="card-footer tx-13">
                        <span class="tx-center"><a href="#" class=" badge block badge-danger">{{ $t('miscellaneous.comingSoon')}}</a></span>
                    </div><!-- card-footer -->
                </div><!-- card -->
            </div><!-- col -->
        </div><!-- row -->
<!--        <hr class="mg-t-30 mg-b-30">-->
    </div>
</template>

<script>
    // import FlotChart from "../FlotChart";
    // import {} from "vue-feather-icons"
    import "jquery"
    import {mapState} from "vuex"
    export default {
        name: "TopCards",
        components: {},
        methods:{
            navigate(_route){
                this.$router.push(_route)
                console.log(_route)
            },
            popToast() {
                this.$notify({
                    group: 'foo',
                    title: 'Important message',
                    text: 'Hello user! This is a notification!'
                });
            }

        },
        computed:{
            ...mapState([
                'company'
            ])
        }
    };
</script>

<style scoped>
    p {
        font-size: smaller !important;
    }
    @media only screen and (min-width: 900px) {
        .top-card {
            width: 103%;
        }
    }
    @media only screen and (min-width: 707px) and (max-width: 900px) {
        .top-card {
            width: 104%;
        }
    }

    @media only screen and (min-width: 517px) and (max-width: 707px) {
        .top-card {
            width: 105%;
        }
    }

    @media only screen and (min-width: 451px) and (max-width: 517px) {
        .top-card {
            width: 106%;
        }
    }

    @media only screen and (max-width: 451px) {
        .top-card {
            width: 107%;
        }
    }
</style>
