import ReportService from "@/service/ReportService";
import swal from "sweetalert";
export const state = {
    reports: [],
    tables: [],
    columns1: [],
    columns2: [],
    reportSingle: [],
    fields: [],
    reportsLoading: false,
    columnsLoading: false,
    response:{
        responseCode:'',
        responseMessage:''
    }

};


export const getters = {

};


export const mutations = {
    updateReports:  (state, payload) =>{
        state.reports = payload
        console.log('mutations updateReports >>>>>>>> ', state.reports)
    },
    updateFields:  (state, payload) =>{
        state.fields = payload
    },
    updateReportsLoading:  (state, payload) =>{
        state.reportsLoading = payload
    },
    updateColumnsLoading:  (state, payload) =>{
        state.columnsLoading = payload
    },
    updateColumns1:  (state, payload) =>{
        state.columns1 = payload;
        console.log("enter mutation payload1>>>>>>>",payload)
        console.log("enter mutation columns1>>>>>>>",state.columns1)
    },
    updateColumns2:  (state, payload) =>{
        state.columns2 = payload
        console.log("enter mutation payload2>>>>>>>",payload)
        console.log("enter mutation columns2>>>>>>>",state.columns2)
    },
    updateTables:  (state, payload) =>{
        state.tables = payload
    },
    updateReportSingle:  (state, payload) =>{
        state.reportSingle = payload
    },
    updateResponse:  (state, payload) =>{
        state.response = payload
    }
};


export const actions = {
    createReport: ({ commit }, payload)=>{
        console.log("updateOfficers>>>>>>")
        commit("updateReportsLoading", true)
        return ReportService.callReportCreateApi(payload)
            .then(response => {
                commit("updateReportsLoading", false)
                let responseData = response.data;
                commit("updateResponse", responseData)
                if (responseData.responseCode === "00") {
                    commit("updateReportsLoading", false)
                    swal('Success!', responseData.responseMessage, 'success')
                }else{
                    swal('Error!', responseData.responseMessage, 'error')
                }

            })
            .catch(error => {
                commit("updateReportsLoading", false)
                alert(error)
                // throw error;
            });
    },
    readReports: ({ commit, state }, payload)=>{
        console.log("updateOfficers>>>>>>");
        if(state.reports.length < 1)
            commit("updateReportsLoading", true)
        return ReportService.callReportReadApi(payload)
            .then(response => {
                commit("updateReportsLoading", false)
                console.log(response.data.data)
                if (response.data.responseCode === "00") {
                    console.log('>>>>>>>++++++++>>>>>>>',response.data)
                    commit("updateReportsLoading", false)
                    let responseData = response.data;
                    commit("updateReports", responseData.data?responseData.data:[])
                }
            })
            .catch(error => {
                commit("updateReportsLoading", false)
                alert(error)
            });
    },
    updateReportSingle: ({ commit }, payload)=>{
        console.log("updateOfficers>>>>>>");
        commit("updateReportsLoading", true)
        return ReportService.callReportReadSingleApi(payload)
            .then(response => {
                commit("updateReportsLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateReportsLoading", false)
                    let responseData = response.data;
                    commit("updateReportSingle", JSON.parse(responseData.reports))
                }
            })
            .catch(error => {
                commit("updateReportsLoading", false)
                alert(error)
            });
    },
    readTables: ({ commit, state }, payload)=>{
        console.log("updateOfficers>>>>>>");
        if(state.tables.length < 1)
            commit("updateReportsLoading", true)
        return ReportService.callReadTablesApi(payload)
            .then(response => {
                commit("updateReportsLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateReportsLoading", false)
                    let responseData = response.data;
                    commit("updateTables", responseData.data)
                }
            })
            .catch(error => {
                commit("updateReportsLoading", false)
                alert(error)
            });
    },
    updateColumns1: ({ commit }, payload)=>{
        console.log("updateOfficers>>>>>>");
        commit("updateColumnsLoading", true)
        commit("updateColumns1", [])
        return ReportService.callReportReadColumnApi(payload)
            .then(response => {
                commit("updateColumnsLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateColumnsLoading", false)
                    let responseData = response.data;
                    console.log("responseData.columns1>>>>>>",responseData.columns)
                    commit("updateColumns1", responseData)
                }
            })
            .catch(error => {
                commit("updateColumnsLoading", false)
                alert(error)
            });
    },
    updateColumns2: ({ commit }, payload)=>{
        console.log("updateOfficers>>>>>>");
        commit("updateColumnsLoading", true)
        commit("updateColumns2", [])
        return ReportService.callReportReadColumnApi(payload)
            .then(response => {
                commit("updateColumnsLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateColumnsLoading", false)
                    let responseData = response.data;
                    console.log("responseData.columns2>>>>>>",responseData.columns)
                    commit("updateColumns2", responseData)
                }
            })
            .catch(error => {
                commit("updateColumnsLoading", false)
                alert(error)
            });
    },
    updateStatus: ({ commit }, payload)=>{
        commit("updateColumnsLoading", false)
        return ReportService.callReportUpdateStatusApi(payload)
    },
};
