<template>
    <div class="body">
        <div class="preloader">
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__bar"></div>
            <div class="preloader__ball"></div>
        </div>
        <br><br><br><br><br>
<!--        <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" class="img-fluid"  height="40px" width="100px" alt="FBNBank Logo">-->
    </div>
</template>

<script>
    // import store from "../../store/store";
    import {mapState} from "vuex";
    export default {
        name: "Preloader",
        data(){
            return{
                finger:''
            }
        },
        beforeCreate() {
            this.$fingerprint.get((components) => {
                this.finger = this.$fingerprint.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
                console.log(this.finger)
                this.$store.commit('auth/updateDeviceId', this.finger)
            });
        },
        mounted() {
            this.$store.dispatch("auth/validate", {}, {root: true}).then(validUser => {
                console.log('validUser>>>>>>>>',validUser)
                if (validUser){
                    // eslint-disable-next-line no-unused-vars
                    this.initDashboard()
                }else
                if (this.$route.fullPath !== '/')
                    this.$router.push('/')
            })
        },
        methods:{
            async initDashboard() {
                // await this.$store.dispatch("loan/updateLoans", {}, {root: true}).then()
                // await this.$store.dispatch("proprietor/updateProprietors", {}, {root: true}).then()
              if (this.auth.userInfo.userType === "IMTO")
                this.$store.dispatch("dashboard/updateAnalytics", {mtoCode:this.auth.userInfo.mtoCode, _startDate:"", _endDate:""}, {root: false}).then()
              else
                this.$store.dispatch("dashboard/updateAnalytics", {_mtoCode:"", _startDate:"", _endDate:""}, {root: false}).then()
              this.$router.push('/dashboard').catch(err => {
                    console.log(err)
                });
            }
        },
        computed: {
            ...mapState([
                'loan',
                'proprietor',
                'auth'
            ]),
        },
    }
</script>

<style lang="scss">
    $bar-color: #3a82ff;
    $ball-color: #3a82ff;
    $bg-color: #ffffff;

    /*.body {*/
    /*    height: 100%;*/
    /*}*/
    .logo{
        position: relative;
    }
    .body {
        height: 780px;
        background: $bg-color;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .preloader {

        position: relative;
        width: 75px;
        height: 100px;

        &__bar {
            position: absolute;
            bottom: 0;
            width: 10px;
            height: 50%;
            background: $bar-color;
            transform-origin: center bottom;
            box-shadow: 1px 1px 0 rgba(0,0,0,.2);

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    left: ($i - 1) * 15px;
                    transform: scale(1,$i*.2);
                    animation: barUp#{$i} 4s infinite;
                }
            }

        }

        &__ball {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 10px;
            height: 10px;
            background: $ball-color;
            border-radius: 50%;
            animation: ball 4s infinite;
        }
    }

    @keyframes ball {
        0% {
            transform: translate(0, 0);
        }
        5% {
            transform: translate(8px, -14px);
        }
        10% {
            transform: translate(15px, -10px)
        }
        17% {
            transform: translate(23px, -24px)
        }
        20% {
            transform: translate(30px, -20px)
        }
        27% {
            transform: translate(38px, -34px)
        }
        30% {
            transform: translate(45px, -30px)
        }
        37% {
            transform: translate(53px, -44px)
        }
        40% {
            transform: translate(60px, -40px)
        }
        50% {
            transform: translate(60px, 0)
        }
        57% {
            transform: translate(53px, -14px)
        }
        60% {
            transform: translate(45px, -10px)
        }
        67% {
            transform: translate(37px, -24px)
        }
        70% {
            transform: translate(30px, -20px)
        }
        77% {
            transform: translate(22px, -34px)
        }
        80% {
            transform: translate(15px, -30px)
        }
        87% {
            transform: translate(7px, -44px)
        }
        90% {
            transform: translate(0, -40px)
        }
        100% {
            transform: translate(0, 0);
        }
    }

    @keyframes barUp1 {
        0% {
            transform: scale(1, .2);
        }
        40%{
            transform: scale(1, .2);
        }
        50% {
            transform: scale(1, 1);
        }
        90% {
            transform: scale(1,1);
        }
        100% {
            transform: scale(1,.2);
        }
    }
    @keyframes barUp2 {
        0% {
            transform: scale(1, .4);
        }
        40% {
            transform: scale(1, .4);
        }
        50% {
            transform: scale(1, .8);
        }
        90% {
            transform: scale(1, .8);
        }
        100% {
            transform: scale(1, .4);
        }
    }
    @keyframes barUp3 {
        0% {
            transform: scale(1, .6);
        }
        100% {
            transform: scale(1, .6);
        }
    }
    @keyframes barUp4 {
        0% {
            transform: scale(1, .8);
        }
        40% {
            transform: scale(1, .8);
        }
        50% {
            transform: scale(1, .4);
        }
        90% {
            transform: scale(1, .4);
        }
        100% {
            transform: scale(1, .8);
        }
    }
    @keyframes barUp5 {
        0% {
            transform: scale(1, 1);
        }
        40% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1, .2);
        }
        90% {
            transform: scale(1, .2);
        }
        100% {
            transform: scale(1, 1);
        }
    }
</style>
