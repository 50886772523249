import AuthService from "../../service/AuthService";
import swal from 'sweetalert'
import router from "../../router";

//export const namespaced = true

export const state = {
    token:null,
    isDocumentsUpt: false,
    authLoading: false,
    deviceId: '',
    screen: 'recovery1',
    loginScreen: 'login',
    regScreen: 'reg',
    userInfo: {
        responseCode: "",
        responseMessage: ""
    },
    users: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalAdminUsers: "0",
        data: []
    },
    response:{}

}


export const getters = {
    getTok: state => {
        // console.log("getter getTok")
        return state.token
    },
    getUser: state => {
        // console.log("getter getTok")
        return state.userInfo
    }
}


export const mutations = {
    updateResponse:(state, payload) =>{
        state.response = payload
    },

    updateDeviceId: (state, payload) =>{
        state.deviceId = payload
    },

    updateUsers: (state, payload) =>{
        state.users = payload
    },

    updateAuthLoading: (state, payload) =>{
        state.authLoading = payload
    },

    updateScreen: (state, payload) =>{
        state.screen = payload
    },

    updateLoginScreen: (state, payload) =>{
        state.loginScreen = payload
    },

    updateUser: (state, payload) =>{
        state.userInfo = payload
    },

    updateRegScreen: (state, payload) =>{
        state.regScreen = payload
    },

    updateAuthToken: (state, payload) =>{
        console.log("mutation updateAuthToken ==>>", payload)
        state.token = payload
    },

    // eslint-disable-next-line no-unused-vars
    reset: (state, payload) =>{
        state.token = null;
        state.isDocumentsUpt = false;
        state.authLoading = false;
        state.screen = 'login';
        state.regScreen = 'reg';
        state.response ={};
    },
}


export const actions = {
    // eslint-disable-next-line no-unused-vars
    logon: ({ commit, dispatch }, payload)=>{
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callLogonApi(payload)
        .then(response => {
            commit("updateAuthLoading", false);
            let responseData = response.data;
            commit("updateResponse", responseData);
            if (responseData.responseCode === "00") {
                localStorage.token = responseData.userToken;
                commit("updateAuthToken", responseData.userToken);
                commit("updateUser", responseData);
                if (payload.username.includes("@")) localStorage.tk = true;
                // swal('Success!',responseData.responseMessage,'success')
                //     .then(res=>{
                //         console.log(res)
                //         window.location = publicPath+'preloader'
                //     });
            }else if (responseData.responseCode === "01") {
                swal(
                    'Info',responseData.responseMessage,'info'
                ).then(res => {
                    console.log(res);
                    commit("updateLoginScreen", 'otp')
                });

            }else if (responseData.responseCode === '95'){
                commit('updateUser', {responseCode: "",responseMessage: ""})
                swal(
                    'Error',responseData.responseMessage,'error'
                ).then(res => {
                    console.log(res);
                    router.push('/forgot-password');
                });
            }else {
                commit('updateUser', {responseCode: "",responseMessage: ""})
                swal('Error!',responseData.responseMessage,'error').then();
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false);
            alert(error+" login");
            // throw error;
            // return Promise.reject(error)
        });
    },

    validate({ commit }) {
        //getting token from local storage
        let rtn = false
        let userToken = localStorage.token;

        //start App Loading
        commit("updateAuthLoading", true);

        if (userToken === "") {
            //stop App Loading
            commit("updateAuthLoading", false);
            router.push('/login');
            // return rtn
        } else {
            let payload = {
                token: ""
            };
            return AuthService.callVerifyTokenApi(payload)
                .then(response=>{
                    commit("updateAuthLoading", false);
                    let responseData = response.data;
                    if (responseData.responseCode === '00'){
                        commit("updateUser", responseData);
                        rtn = true
                        return rtn
                    }else{
                        return rtn
                    }
                })
                .catch(error=>{
                    commit("updateAuthLoading", false);
                    alert(error)
                    return rtn
                })
        }

    },

    initiateResetPassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callInitiateResetPasswordApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                commit("updateAuthLoading", false)
                localStorage.clear()
                swal('Success!',responseData.responseMessage,'success')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery2')});
            }else{
                commit("updateAuthLoading", false)
                swal('Error!',responseData.responseMessage,'error')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery1')});
            }
            
        })
        .catch(error => {
            commit("updateScreen",'recovery1')
            commit("updateAuthLoading", false)
            alert(error);
            // throw error;
        });
    },

    completeResetPassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callCompleteResetPasswordApi(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateAuthLoading", false)
                if (responseData.responseCode === "00") {
                    localStorage.clear();
                    swal('Success!',responseData.responseMessage,'success')
                        // eslint-disable-next-line no-unused-vars
                        .then(res=>{
                            commit("updateScreen",'recovery1')
                            router.push('/');
                        });
                }else{
                    swal('Error!',responseData.responseMessage,'error')
                        // eslint-disable-next-line no-unused-vars
                        .then(res=>{
                            commit("updateScreen",'recovery1')
                            // router.push('/');
                        });
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error);
                // throw error;
            });
    },

    validateHardToken: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callValidateHardTokenApi(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateAuthLoading", false)
                if ((responseData.responseCode?responseData.responseCode:responseData.ResponseCode) === "00") {
                    localStorage.tk = true;
                    window.location = window.__env.app.publicPath+'preloader'
                }else{
                    swal('Error!','Invalid Token','error').then();
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error);
                // throw error;
            });
    },

    verifyToken: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callVerifyTokenApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateAuthLoading", false)
            if (responseData.responseCode === "00") {
                // console.log("callVerifyTokenApi responseData ==>>", responseData);
                localStorage.clear()
                swal('Info!',responseData.responseMessage,'info')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{commit("updateScreen",'recovery3')});
            }else{
                swal('Error!',responseData.responseMessage,'error')
                    // eslint-disable-next-line no-unused-vars
                    .then();
            }
            
        })
        .catch(error => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    changePassword: ({ commit }, payload)=>{
        commit("updateAuthLoading", true)
        return AuthService.callChangePasswordApi(payload)
        .then(response => {
            let responseData = response.data;
            commit("updateAuthLoading", false)
            if (responseData.responseCode === "00") {
                swal('Success!',responseData.responseMessage,'success')
            }else{
                swal('Error!',responseData.responseMessage,'error')
            }

        })
        .catch(error => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    initiateEnrollment: ({ commit }, payload)=>{
        delete payload.dialogs;
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callInitiateEnrollmentApi(payload)
        .then(response => {
            commit("updateAuthLoading", false)
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                swal('Info!',responseData.responseMessage,'info').then(commit("updateRegScreen", 'otp'));
            }else{
                swal('Error!',responseData.responseMessage,'error')//.then(router.push('/').then);
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false)
            alert(error);
        });
    },

    completeEnrollment: ({ commit }, payload)=>{
        delete payload.dialogs;
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callCompleteEnrollmentApi(payload)
        .then(response => {
            commit("updateAuthLoading", false)
            let responseData = response.data;
            commit("updateResponse", responseData)
            if (responseData.responseCode === "00") {
                swal('Success!',responseData.responseMessage,'success')
                    // eslint-disable-next-line no-unused-vars
                    .then(res=>{
                        commit("updateRegScreen", 'reg')
                        router.push('/')
                    });
            }else{
                swal('Error!',responseData.responseMessage,'error').then();
            }
            
        })
        .catch((error) => {
            commit("updateAuthLoading", false)
            alert(error);
            // throw error;
            // return Promise.reject(error)
        });
    },

    updateUsers: ({ commit, state }, payload)=>{
        if(state.users.data.length < 1)
            commit("updateAuthLoading", true)
        return AuthService.callGetUsersApi(payload)
            .then(response => {
                commit("updateAuthLoading", false)
                if (response.data.responseCode === "00") {
                    commit("updateAuthLoading", false)
                    let responseData = response.data;
                    commit("updateUsers", responseData)
                }

            })
            .catch(error => {
                commit("updateAuthLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateUser: ({ commit }, payload)=>{
        commit("updateUser",payload)
    },

    logout: ({ commit }, payload)=>{
        delete payload.dialogs;
        commit("updateAuthLoading", true)
        commit("updateResponse", {})
        return AuthService.callLogoutApi(payload)
            .then(response => {
                commit("updateAuthLoading", false)
                let responseData = response.data;
                commit("updateResponse", responseData)
                localStorage.clear()
                window.location = window.__env.app.publicPath;
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error) => {
                commit("updateAuthLoading", false)
                localStorage.clear()
                window.location = window.__env.app.publicPath;
            });
    },

    reset: ({ commit }, payload)=>{
        commit("reset",payload)
    }

}
