<template>
  <b-modal id="user-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="user-create-modal">
    <div class="modal-body">
      <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
         @click="hide()">
        <span aria-hidden="true">&times;</span>
      </a>
      <div>
        <h4 v-if="model.type === 'edit'">Edit CashPickup</h4>
        <h4 v-else>Add New CashPickup</h4>
        <b-form v-if="transaction.screen === 'enquiry'" @submit.prevent="enquiry" enctype="multipart/form-data">
          <b-form-group label="Transaction Reference *">
            <b-input type="text" placeholder="Transaction Reference" required v-model="model.transactionReference"/>
          </b-form-group>
          <b-form-group label="Select MTO *">
            <b-select v-model="model.transactionMtoCode">
              <b-select-option value="">Select MTO</b-select-option>
              <b-select-option v-for="mto in mtos().filter(it =>it.mtoStatus === 'ACTIVE')" :value="mto.mtoCode" v-bind:key="mto.mtoCode">{{ mto.mtoName }}</b-select-option>
            </b-select>
          </b-form-group>
          <base-button title="Continue" :loading="transaction.transactionLoading"/>
        </b-form>
        <b-form v-else @submit.prevent="submit" enctype="multipart/form-data">
          <b-card>
            <ul v-if="model != null">
              <li v-for="(value, key) in model" :key="key">
                    <span v-if="!isJson(value)">
                      <b>{{ key | camelToSentenceCase }}</b>: {{ value }}
                    </span>
                <span v-else-if="value.length < 1">
                      <ul>
                        <li v-for="(val, ky) in value" :key="ky">
                          {{ ky }}: {{ val }}
                        </li>
                      </ul>
                    </span>
                <span v-else>
                      {{ key }}:
                      <sub-base-table
                          style="margin-top: -30px;"
                          :fields="fields"
                          :items="value"
                          :show-title="false"
                          filter-mode="accountingRuleEntries"
                      ></sub-base-table>
                    </span>
              </li>
            </ul>
          </b-card>
          <base-button title="Submit" :loading="transaction.transactionLoading"/>
        </b-form>
      </div>
    </div><!-- modal-body -->
  </b-modal>
</template>
<script>
import { mapState } from "vuex"
import BaseButton from "../button/BaseButton";
export default {
  props:['show','row'],
  components:{BaseButton},
  name: 'ConfirmModal',
  data(){
    return{
      password: '',
      model:{
        transactionMaker:'',
        transactionAmount:'',
        transactionBatchReference:'',
        transactionContractReference:'',
        transactionCurrency:'',
        transactionDate:'',
        transactionDestinationCountryCode:'',
        transactionDestinationCountryCurrency:'',
        transactionId:'',
        transactionMtoCode:'',
        transactionNarration:'',
        transactionPurposeCode:'',
        transactionReceiverAccountNumber:'',
        transactionReceiverBankCode:'',
        transactionReceiverCity:'',
        transactionReceiverEmailAddress:'',
        transactionReceiverFirstName:'',
        transactionReceiverGender:'',
        transactionReceiverLastName:'',
        transactionReceiverNationality:'',
        transactionReceiverPhoneNumber:'',
        transactionReceiverStreet:'',
        transactionReference:'',
        transactionSenderBirthDate:'',
        transactionSenderCityAddress:'',
        transactionSenderEmailAddress:'',
        transactionSenderFirstName:'',
        transactionSenderGender:'',
        transactionSenderIdExpiryDate:'',
        transactionSenderIdIssueDate:'',
        transactionSenderIdIssuer:'',
        transactionSenderIdNumber:'',
        transactionSenderIdType:'',
        transactionSenderImage:'',
        transactionSenderLastName:'',
        transactionSenderNationality:'',
        transactionSenderOccupationCode:'',
        transactionSenderPhoneNumber:'',
        transactionSenderRelationship:'',
        transactionSenderStreetAddress:'',
        transactionSourceCode:'',
        transactionSourceCountryCode:'',
        transactionSourceCountryCurrency:'',
        transactionStatus:'',
        transactionTime:'',
        responseCode:'',
        responseMessage:''
      },
    }
  },
  methods:{
    hide() {
      this.$refs['user-create-modal'].hide()
    },
    async enquiry(){
      this.model.transactionMaker = this.auth.userInfo.userId
      await this.$store.dispatch("transaction/updateCpTransaction", this.model, {root: false}).then(()=>{
        this.model = this.transaction.cpTransaction
      });
      //this.hide();
    },
    async submit(){
      this.model.transactionMaker = this.auth.userInfo.userId
      this.model.source = 'WEB'
      if (this.model.type === 'edit')
        await this.$store.dispatch("transaction/editCpTransaction", this.model, {root: false});
      else {
        //this.model.userId = this.row.roleId
        await this.$store.dispatch("transaction/createCpTransaction", this.model, {root: false});
      }
      this.hide();
      this.loadData()
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });

    },
    isJson(_json){
      if (_json == null) return false
      let reg = new RegExp('^[0-9]+$')
      console.log(reg.test(_json.toString()))
      if (reg.test(_json.toString().replace('.',''))) return false
      if (typeof _json === 'object') return true
      try {
        JSON.parse(_json.toString())
        console.log('>>>>>>>>>',_json.toString())
      }catch (e){
        return false
      }
      return true
    },
    loadData(){
      this.$store.dispatch('user/updateUsers', {
        readAll: 'YES',
        source: 'WEB'
      })
    },
    hideauth: function () {
      this.password = '';
      this.$refs['user-create-modal'].hide();
    },
    currentDate(){
      let date = new Date()
      date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
      console.log(date)
      return date
    },
    mtos(){
      return  this.user.mtos.data
    },
  },
  mounted() {
    //this.model = this.row readSkillCategory
    //this.$store.dispatch('role/readSkillCategory',{readAll:"YES"})
  },
  computed:{
    ...mapState(['transaction','auth','role','user']),
    roles(){
      return  this.role.roles.data?this.role.roles.data:[]
    },
  },
  watch: {
    show: function () {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.model).forEach(function(key,index) {
        self.model[key] = '';
      });
      if (this.type !== 'edit') this.$store.commit("transaction/updateScreen", 'enquiry')
      this.$store.dispatch('role/updateRoles',{readAll:"YES"})
      // eslint-disable-next-line no-unused-vars
      Object.keys(this.row).forEach(function(key,index) {
        self.model[key] = self.row[key];
      });
      this.$store.dispatch("user/updateMtos", {cqMode: 'READ',source: 'WEB'}, {root: false}).then()
      this.$refs['user-create-modal'].show()
    }
  },
}
</script>
