<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0"><b-icon-arrow-left style="cursor: pointer;" v-if="screen === 'two' && this.accountingRule.slab.responseCode !== '00'" @click="screen = 'one'"/> Accounting Rule Setup</h6>
          <div class="d-flex tx-18">
          </div>
        </div>
        <b-form @submit.prevent="submitEntries" v-if="screen === 'two'" class="p-4">
          <b-form-row >
            <b-col md="6">
              <b-form-group label-for="slabCode" label="Slab Code *">
                <b-input required :readonly="this.accountingRule.slab.responseCode === '00'" maxlength="10" id="slabCode" v-model="model1.slabCode"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="slabMaxAmount" label="Maximum Amount *">
                <money class="form-control"  v-model="model1.slabMaxAmount" v-bind="money"></money>
<!--                <the-mask id="slabMaxAmount" type="text" required v-model="model1.slabMaxAmount" :mask="['###,###,###,###,###', '###,###,###,###,###']" class="form-control" />-->
<!--                <b-input required id="slabMaxAmount" v-model="model1.slabMaxAmount"></b-input>-->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-row>
                <b-col md="7">
<!--                  <label style="margin-bottom: -100px;">Entries</label>-->
                  <base-table
                      :items="accountingRule.slab.entries"
                      :fields="fields"
                      :show-title="true"
                      :is-busy="accountingRule.accountingRuleLoading"
                      class="mt-2"
                      filter-mode="accountingRuleEntries"
                  ></base-table>
                </b-col>
                <b-col md="5">
                  <b-form @submit.prevent="addEntry">
                    <b-form-group label-for="entryAccount" label="Account *">
                      <p style="position:absolute; right: 5px; top: -2px;"><b-checkbox v-model="isPrincipalAccount"> Principal Account</b-checkbox></p>
                      <b-input required v-if="!isPrincipalAccount" id="entryAccount" v-model="entry.entryAccount"></b-input>
                      <b-input v-else id="entryAccount" value="Principal Account" readonly></b-input>
                    </b-form-group>
                    <b-form-group label-for="entryAmountType" label="Amount Type">
                      <b-select required @change="entryAmountTypeChange" v-model="entry.entryAmountType">
                        <b-select-option value="FLAT">Flat</b-select-option>
                        <b-select-option value="PERCENT">Percent</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label-for="entryAmount" label="Amount *">
                      <p v-if="entry.entryAmountType === 'FLAT'" style="position:absolute; right: 5px; top: 170px;"><b-checkbox v-model="isPrincipalAmount"> Principal Amount</b-checkbox></p>
                      <b-input required v-if="!isPrincipalAmount"  type="number" min="2" step="0.01" id="entryAmount" v-model="entry.entryAmount"></b-input>
                      <b-input v-else id="entryAmount" value="Principal Amount" readonly></b-input>
                    </b-form-group>
                    <b-form-group label-for="entryDrCr" label="DrCr *">
                      <b-select required v-model="entry.entryDrCr">
                        <b-select-option value="CR">CR</b-select-option>
                        <b-select-option value="DR">DR</b-select-option>
                      </b-select>
                    </b-form-group>
                    <base-button title="Add Entry" :loading="accountingRule.accountingRuleLoading"/>
                  </b-form>
                </b-col>
              </b-form-row>
            </b-col>
          </b-form-row>
          <hr/>
          <b-form-row>
            <b-col md="7">
              <p style="text-align: center; font-weight: bolder; font-size: 24px;">Total: {{netFlat}} ~ {{netPercent+'%'}}</p>
            </b-col>
            <b-col md="5">
              <base-button v-if="netFlat === 0 && netPercent === 0 && entriesLength > 0" variant="success" title="Submit Entry" :loading="accountingRule.accountingRuleLoading"/>
            </b-col>
          </b-form-row>
        </b-form>
        <b-form v-else class="p-4" @submit.prevent="createAccountingRule">
          <b-form-row>
            <b-col md="6">
              <b-form-group label-for="aeCode" label="Accounting Rule Code *">
                <b-input required id="aeCode" v-model="model.aeCode"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="aeDescription" label="Description *">
                <b-input required id="aeDescription" v-model="model.aeDescription"></b-input>
              </b-form-group>
            </b-col>
            <b-col md="10"></b-col>
            <b-col md="2">
              <base-button title="Continue" :loading="accountingRule.accountingRuleLoading"/>
            </b-col>
          </b-form-row>
        </b-form>
      </div><!-- card -->
    </div>
  </div><!-- row -->
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
import BaseButton from "@/components/button/BaseButton";
import BaseTable from "@/components/table/BaseTable";
export default {
  name: "TopCards",
  props:['slab'],
  components:{
    BaseTable,
    BaseButton

  },
  data(){
    return {
      screen:'',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      value: [
        { name: 'Javascript', code: 'js' }
      ],
      options: [

      ],
      fields: [
        // { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'entryDrCr', label: 'Dr_Cr', sortable: true, sortDirection: 'desc',  class: 'text-left'  },
        { key: 'entryAccount', label: 'Account', sortable: true, sortDirection: 'desc', class: 'text-left'  },
        { key: 'entryAmount', label: 'Amount', sortable: true, class: 'text-right' },
        // { key: 'auditModule', label: 'Module', sortable: true, class: 'text-left' },
        { key: 'actionsAccountingRuleEntries', label: 'Action', sortable: true, class: 'text-center' }
      ],
      model:{
        aeCode:'',
        aeDescription:'',
        aeChargeCode:'CHG001',
        aeTransactionCode:'TRN001',
        aeMaker:''
      },
      isPrincipalAccount:false,
      isPrincipalAmount:false,
      entry:{
        entryAccount:'',
        entryAmountType:'FLAT',
        entryAmount:'',
        entryId:'',
        entryDrCr:''
      },
      model1:{
        aeMaker: '',
        aeTransactionCode: '',
        aeDescription: '',
        aeChargeCode: '',
        aeCode: '',
        entryAeCode: '',
        entryMaker: '',
        slabCode: '',
        slabMaxAmount: 99999999999999999,
        entries: []
      },
      roles: ['initiator','verifier','authorizer']
    };
  },
  methods: {
    addEntry(){
      if (this.isPrincipalAmount) this.entry.entryAmount = '$AMOUNT'
      if (this.isPrincipalAccount) this.entry.entryAccount = '$ACCOUNT'
      this.entry.entryId = this.accountingRule.slab.entries.length + 1
      let entry = {}
      entry.entryAccount = this.entry.entryAccount
      entry.entryAmountType = this.entry.entryAmountType
      entry.entryAmount = this.entry.entryAmount
      entry.entryId = this.entry.entryId
      entry.entryDrCr = this.entry.entryDrCr
      this.$store.commit('accountingRule/addEntryToSlab', this.entry)
      this.isPrincipalAccount = false;
      this.isPrincipalAmount = false;
      this.entry = {
        entryAccount:'',
        entryAmountType:'FLAT',
        entryAmount:'',
        entryId:'',
        entryDrCr:''
      }
    },
    entryAmountTypeChange(){
      if (this.entry.entryAmountType === 'PERCENT') this.isPrincipalAmount = false
    },
    async submitEntries(){
      this.model1.entryMaker = this.auth.userInfo.userId
      this.model1.entryAeCode = this.model.aeCode
      this.model1.entries = this.accountingRule.slab.entries
      this.model1.aeCode = this.model.aeCode
      this.model1.aeChargeCode = this.model.aeChargeCode
      this.model1.aeDescription = this.$route.params.row?this.$route.params.row.aeDescription:this.model.aeDescription
      this.model1.aeTransactionCode = this.model.aeTransactionCode
      this.model1.aeMaker = this.auth.userInfo.userId
      //await this.$store.dispatch("accountingRule/createEntries", this.model1, {root: true}).then();
      await this.$store.dispatch("accountingRule/createAccountingRule", this.model1, {root: true}).then();
      if (this.accountingRule.response.responseCode === '00') {
        this.screen = 'one'
        this.model = {
          aeCode:'',
          aeDescription:'',
          aeChargeCode:'CHG001',
          aeTransactionCode:'TRN001',
          aeMaker:''
        }
        this.model1 = {
          entryAeCode: '',
          entryMaker: '',
          slabCode: '',
          slabMaxAmount: 99999999999999999,
          entries: []
        }
        this.$store.commit('accountingRule/updateSlab',{
          responseCode: '',
          responseMessage: '',
          entries: []
        })
      }

    },
    async createAccountingRule(){
      this.model.aeMaker = this.auth.userInfo.userId
      // await this.$store.dispatch("accountingRule/createAccountingRule", this.model, {root: true}).then();
      // if (this.accountingRule.response.responseCode === '00' && this.accountingRule.response.responseMessage !== 'Activity Queued Awaiting Authorization') {
        this.screen = 'two'
        this.slab = 'SlabOne'
        this.model1.slabCode = 'SlabOne'
      // }
      // else{
      //   this.model = {
      //     aeCode:'',
      //     aeDescription:'',
      //     aeChargeCode:'CHG001',
      //     aeTransactionCode:'TRN001',
      //     aeMaker:''
      //   }
      // }
    },
    showAlert(title, message) {
      const options = {title: title, size: 'sm'}
      this.$dialogs.alert(message, options)
          .then()
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.value.push(tag)
    }
  },
  computed:{
    ...mapState([
      'auth',
      'accountingRule'
    ]),
    entriesLength(){
      return this.accountingRule.slab.entries.length
    },
    netFlat(){
      return this.accountingRule.slab.entries.reduce((init, curr) => (
          init+=curr.entryAmountType === 'FLAT'?((curr.entryDrCr==='CR'?1:-1)*parseFloat(curr.entryAmount.toString().replace('$AMOUNT','1'))):0),0)
    },
    netPercent(){
      return this.accountingRule.slab.entries.reduce((init, curr) => (
          init+=curr.entryAmountType === 'PERCENT'?((curr.entryDrCr==='CR'?1:-1)*parseFloat(curr.entryAmount.toString().replace('$AMOUNT','1'))):0),0)
    }
  },
  mounted() {
    //this.model1.
    console.log(this.$route.query)
    if (this.$route.query.aeCode == null || this.$route.query.aeCode === '') {
      this.screen = ''
      this.$store.commit('accountingRule/updateSlab',{
        responseCode: '',
        responseMessage: '',
        entries: []
      })
    }
    else {
      this.screen = 'two'
      this.model.aeCode = this.$route.query.aeCode
      this.slab = this.accountingRule.slab.slabCode
      this.model.slab = this.accountingRule.slab.slabCode
      this.model1.slabCode = this.accountingRule.slab.slabCode
      this.model1.slabMaxAmount = this.accountingRule.slab.slabMaxAmount
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
.h-100 {
  height: 100vh !important;
}
@media only screen and  (max-width: 991px) {
  .extra{
    display: none;
  }
}
.image-input{
  display: block;
  width: 100%;
  height: 300px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder{
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica,serif;
}

.placeholder:hover{
  background: #E0E0E0
}

.file-input{
  display: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>