<template>
    <b-modal id="skill-create-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="skill-create-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4 v-if="model.type === 'edit'">Create Report Schedule</h4>
                <h4 v-else>Create Report Schedule</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="Frequency">
                      <b-select>
                        <b-select-option value="">Select Frequency</b-select-option>
                        <b-select-option value="Daily">Daily</b-select-option>
                        <b-select-option value="Weekly">Weekly</b-select-option>
                        <b-select-option value="Monthly">Monthly</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Delivery file format">
                      <b-select>
                        <b-select-option value="">Select Format</b-select-option>
                        <b-select-option value="CSV">CSV</b-select-option>
                        <b-select-option value="PDF">PDF</b-select-option>
                        <b-select-option value="EXCEL">EXCEL</b-select-option>
                      </b-select>
                    </b-form-group>
                    <b-form-group label="Destination Email">
                        <input type="email" class="form-control"  placeholder="Destination Email" required v-model="model.mtoEmail"/>
                    </b-form-group>
                    <base-button title="Submit" :loading="user.userLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    import swal from "sweetalert";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ReportSchedulerModal',
        data(){
            return{
                password: '',
                model:{
                  mtoUserFirstName: '',
                  mtoUserLastName: '',
                  mtoCountryCode: '',
                  mtoCode: '',
                  mtoName: '',
                  mtoCountry: '',
                  mtoAddress: '',
                  mtoMaker: '',
                  mtoPhone: '',
                  mtoEmail: '',
                  mtoCbnLicenceNo: '',
                  mtoCbnLicense: '',
                  mtoUserMaker: '',
                  mtoFinacleId: '',
                  mtoAccounts: '',
                  accounts: [],
                  mtoStatus: 'PENDING',
                  mtoAccountingRuleDc: '',
                  mtoAccountingRuleDcNewAccount: '',
                  mtoNdaExecuted: '',
                  mtoVpnConnectionSetup: '',
                  mtoAccountingRuleCp: '',
                  mtoPcidssCertificate: '',
                  mtoCacIncorporationDocuments: '',
                  mtoMemorandumAndArticlesOfAssociation: '',
                  mtoConsumerProtectionAndDisputeMechanism: '',
                  mtoEnterpriseRiskManagementFramework: '',
                  mtoContingencyAndDisasterRecovery: '',
                  mtoContractAgreement: '',
                },
            }
        },
        methods:{
            filterOutBadChar(){
              this.model.mtoUserFirstName = this.model.mtoUserFirstName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoUserLastName = this.model.mtoUserLastName.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoCode = this.model.mtoCode.replace(/[^A-Z0-9\s]/i, '')
              this.model.mtoAccounts = this.model.mtoAccounts.replace(/[^0-9,\s]/i, '')
              this.model.mtoPhone = this.model.mtoPhone.replace(/[^0-9]/i, '')
            },
            hide() {
                this.$refs['skill-create-modal'].hide()
            },
            onSelect({name, iso2, dialCode}) {
              this.model.mtoCountryCode =  dialCode
              console.log(name, iso2, dialCode);
            },
            async submit(){
              swal('Success!', "Report Scheduled Successfully!", 'success').then()
                // this.model.mtoMaker = this.auth.userInfo.userId
                // // if (this.model.mtoAccounts !== '')
                // //   this.model.mtoAccounts = this.model.accounts.join()
                // // delete this.model.accounts
                // if (this.model.type !== 'edit')
                //   delete this.model.mtoStatus
                // delete this.model.mtoFinacleId
                // delete this.model.mtoUserMaker
                // if (this.model.type === 'edit')
                //   await this.$store.dispatch("user/editMto", this.model, {root: false});
                // else {
                //   //this.model.userEmployeeId = this.row.employeeId
                //   await this.$store.dispatch("user/createMto", this.model, {root: false});
                // }
                // this.hide();
                // this.$store.dispatch('user/updateMtos', {
                //   readAll: 'NO',
                //   employerId: localStorage.orgId,
                //   employeeId: localStorage.orgId
                // })
                // let self = this;
                // // eslint-disable-next-line no-unused-vars
                // Object.keys(this.model).forEach(function(key,index) {
                //     self.model[key] = '';
                // });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch("grade/updateGradeSalaries", {
                    readAll:'NO',
                    employerGradeSalaryEmployerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['skill-create-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
          this.$store.dispatch("accountingRule/updateAccountingRules", {readAll: 'YES',source: 'WEB'}, {root: false}).then()
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('employee/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['auth','user','accountingRule'])
        },
        watch: {
            show: function () {
                let self = this;
              // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                //this.model.accounts = this.model.mtoAccounts.split(',')
                this.$refs['skill-create-modal'].show()
            }
        },
    }
</script>
