import UserService from "@/service/UserService";
import swal from "sweetalert";

//export const namespaced = true

export const state = {
    users: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalOnlineUsers: "0",
        totalInactiveUsers: "0",
        data: []
    },
    mtoUsers: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalOnlineUsers: "0",
        totalInactiveUsers: "0",
        data: []
    },
    auditTrails: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalOnlineUsers: "0",
        data: []
    },
    auditTrailsMto: {
        totalUsers: "0",
        totalPendingUsers: "0",
        totalActiveUsers: "0",
        totalOnlineUsers: "0",
        data: []
    },
    mtos: {
        responseCode: "",
        responseMessage: "",
        data: []
    },
    adUser:{
        department:'',
        displayName:'',
        email:'',
        groups:'',
        managerDepartment:'',
        managerName:'',
        mobileNo:'',
        requestId:'',
        responseCode:'',
        responseMessage:'',
        username:''
    },
    screen:'enquiry',
    response:{},
    userLoading: false

}


export const getters = {

}


export const mutations = {
    updateResponse:(state, payload) =>{
        state.response = payload
    },

    updateUsers: (state, payload) =>{
        state.users = payload
    },

    updateMtoUsers: (state, payload) =>{
        state.mtoUsers = payload
    },

    updateMtos: (state, payload) =>{
        state.mtos = payload
    },

    updateAuditTrails: (state, payload) =>{
        state.auditTrails = payload
    },

    updateAuditTrailsMto: (state, payload) =>{
        state.auditTrailsMto = payload
    },

    updateAdUser: (state, payload) =>{
        state.adUser = payload
    },

    updateScreen: (state, payload) =>{
        state.screen = payload
    },

    updateUserLoading: (state, payload) =>{
        state.userLoading = payload
    },
}


export const actions = {

    updateUsers: ({ commit, state }, payload)=>{
        if(state.users.data.length < 1)
            commit("updateUserLoading", true)
        return UserService.callGetUsersApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateUsers", responseData)
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateMtoUsers: ({ commit, state }, payload)=>{
        if(state.mtoUsers.data.length < 1)
            commit("updateUserLoading", true)
        return UserService.callReadMtoUserByCodeApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateMtoUsers", responseData)
                }

            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    createMtoUser: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callCreateMtoUserApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (response.data.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    editMtoUser: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callEditMtoUserApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (response.data.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    editMto: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callEditMtoApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (response.data.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateAuditTrails: ({ commit, state }, payload)=>{
        if(state.users.data.length < 1)
            commit("updateUserLoading", true)
        return UserService.callReadAuditTrailApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateAuditTrails", responseData)
                }

            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateAuditTrailsMto: ({ commit, state }, payload)=>{
        if(state.users.data.length < 1)
            commit("updateUserLoading", true)
        return UserService.callReadAuditTrailMtoApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                if (response.data.responseCode === "00") {
                    let responseData = response.data;
                    commit("updateAuditTrailsMto", responseData)
                }

            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateAdUser: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callGetAdUsersApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateAdUser", responseData)
                    commit("updateScreen", 'create')
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }

            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateMtos: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callReadMtoApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateMtos", responseData)
                }else{
                    swal('Error!', responseData.ResponseMessage, 'error').then()
                }

            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    createUser: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callCreateUsersApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (response.data.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    editUser: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callEditUsersApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (response.data.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    createMto: ({ commit }, payload)=>{
        commit("updateUserLoading", true)
        return UserService.callCreateMtoApi(payload)
            .then(response => {
                commit("updateUserLoading", false)
                let responseData = response.data
                if (responseData.responseCode === "00") {
                    swal('Success!', responseData.responseMessage, 'success').then()
                }else{
                    swal('Error!', responseData.responseMessage, 'error').then()
                }
            })
            .catch(error => {
                commit("updateUserLoading", false)
                alert(error)
                // throw error;
            });
    },

    updateUser: ({ commit }, payload)=>{
        commit("updateUser",payload)
    },

    reset: ({ commit }, payload)=>{
        commit("reset",payload)
    }

}
