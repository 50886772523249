<template>
    <b-modal id="accounting-rule-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="accounting-rule-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
              <a href="#" @click="navigate(row.aeCode)"><b-icon-plus/> Add New Slab</a>
              <b-card v-if="!accountingRule.accountingRuleLoading">
                <span v-if="accountingRule.slabs.data.length > 0">
                  <p @click="preview(value)" class="slab-btn" v-for="(value, key) in accountingRule.slabs.data" :key="key">{{value.slabCode}} <span style="position: absolute; right: 30px;">{{value.slabMaxAmount}}</span></p>
                </span>
                <p style="font-style: italic; color: #4f4f51; text-align: center;" v-else>No Slabs Created...</p>
              </b-card>
              <b-card v-else>
                  <b-spinner small variant="info"/>
              </b-card>
            </div>
        </div><!-- modal-body -->
        <entries-preview-modal :show="showModal" :row="row" :slabModel="modalRow" />
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import EntriesPreviewModal from "@/components/modal/EntriesPreviewModal";
    // import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{EntriesPreviewModal},
        name: 'ConfirmModal',
        data(){
            return{
                showModal: false,
                modalRow:{},
                password: '',
                model:{
                  cqId: '',
                  cqJson: '',
                  cqType: '',
                  cqMode: '',
                  cqBankCode: '',
                  cqReason: '',
                  cqChecker: ''
                },
            }
        },
        methods:{
            preview(_row){
              console.log(_row)
              this.modalRow = _row
              this.showModal = !this.showModal
              //this.$refs['accounting-rule-modal'].hide()
            },
            navigate(_id){
              console.log(_id)
              this.$router.push({ path: '/create-accounting-rule', params: { aeCode: _id }, query: { aeCode: _id } })
            },
            hide() {
                this.$refs['accounting-rule-modal'].hide()
            },
            async submit(_mode){
                this.model.cqId = this.row.cqId
                this.model.cqJson = this.row.cqJson
                this.model.cqType = this.row.cqType
                this.model.cqMode = _mode
                this.model.cqChecker = this.auth.userInfo.userId
                this.model.cqBankCode = '000000'
                await this.$store.dispatch("queue/authorizeQueue", this.model, {root: false});
                this.hide();
                this.loadData()
                // let self = this;
                // eslint-disable-next-line no-unused-vars
                // Object.keys(this.model).forEach(function(key,index) {
                //     self.model[key] = '';
                // });

            },
            loadData(){
              this.$store.dispatch('queue/updateQueues', {cqMode: 'READ',source: 'WEB'})
            },
            hideauth: function () {
                this.password = '';
                this.$refs['accounting-rule-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row readSkillCategory
          //this.$store.dispatch('role/readSkillCategory',{readAll:"YES"})
        },
        computed:{
            ...mapState(['auth','role','accountingRule']),
          roles(){
            return  this.role.roles.data?this.role.roles.data:[]
          },
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                  self.model[key] = '';
                });
                // if (this.type !== 'edit') this.$store.commit("user/updateScreen", 'enquiry')
                this.$store.dispatch('accountingRule/updateSlabs',{slabAeCode:this.row.aeCode})
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                this.$refs['accounting-rule-modal'].show()
            }
        },
    }
</script>
<style scoped>
  .slab-btn{
    background-color: #eaeaea;
    padding: 13px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 3px;
  }
  .slab-btn:hover{
    background-color: #dbdbdb;
  }
</style>
