<template>
  <div class="row">
    <div class="col-md-12 mg-t-10">
      <div class="card ht-100p">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h6 class="mg-b-0">Create Report</h6>
          <div class="d-flex tx-18"></div>
        </div>
        <form v-if="screen ===  'one'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next('two')">
          <strong>1. Set up a Report</strong><br><br>
          <p style="line-height: 0;">Set up a report however you want it.</p>
          <p style="line-height: 0;">By using one or joining any two of (tables and existing reports).</p>
          <p style="line-height: 0;">Set their join contract and the columns you want the report to contain</p><br>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Report Name:</label>
                <input type="text" required  v-model="model.reportName" class="form-control" name="reportName" placeholder="Report Name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Report Category:</label>
                <b-select required v-model="model.reportCategory" >
                  <b-select-option value="IT">IT</b-select-option>
                  <b-select-option value="TREASURY">TREASURY</b-select-option>
                  <b-select-option value="TREASURY OPERATIONS">TREASURY OPERATIONS</b-select-option>
                  <b-select-option value="TRADE">TRADE</b-select-option>
                  <b-select-option value="E-BUSINESS">E-BUSINESS</b-select-option>
                  <b-select-option value="FRONT OFFICE OPERATIONS">FRONT OFFICE OPERATIONS</b-select-option>
                  <b-select-option value="BRANCH OPERATIONS">BRANCH OPERATIONS</b-select-option>
                  <b-select-option value="MARKETING">MARKETING</b-select-option>
                  <b-select-option value="LEGAL">LEGAL</b-select-option>
                  <b-select-option value="GENERAL SERVICES">GENERAL SERVICES</b-select-option>
                  <b-select-option value="MD OFFICE">MD OFFICE</b-select-option>
                  <b-select-option value="OTHERS">OTHERS</b-select-option>
                </b-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Report Description:</label>
                <input type="text" required  v-model="model.reportDescription" class="form-control" name="reportName" placeholder="Report Description">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Report Type:</label>
                <b-form-radio-group required class="mt-2" id="radio-group-2" v-model="model.reportType" name="radio-sub-component">
                  <b-form-radio value="single">Single Source</b-form-radio>
                  <b-form-radio value="join">Join Source</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-10">

            </div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'two'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next(model.reportType==='join'?'three':'five')">
          <strong>2. Report Start Table</strong><br><br>
          <p style="line-height: 0;"><code>Report Start Table</code> refers to the table you want to start the report creation from..</p>
          <p style="line-height: 0;">You can choose a Table or an existing Report.</p><br>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Table Type:</label>
                <b-select required v-model="model.reportTableType">
                  <b-select-option value="table">Table</b-select-option>
                  <b-select-option  value="report">Report</b-select-option>
                </b-select>
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="model.reportTableType === 'table'" class="form-group">
                <label>Start Table:</label>
                <b-select required v-model="model.reportTable">
                  <b-select-option v-for="table in report.tables" :value="table.tableName" v-bind:key="table.tableName">{{table.tableName}}</b-select-option>
                </b-select>
              </div>
              <div v-else class="form-group">
                <label>Start Table:</label>
                <b-select required v-model="model.reportTable">
                  <b-select-option v-for="table in report.reports" :value="table.reportName" v-bind:key="table.reportName">{{table.reportName}}</b-select-option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer;"  @click="next('one')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'three'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next('four')">
          <strong>3. Report Join Table</strong><br><br>
          <p style="line-height: 0;"><code>Report Join Table</code> refers to the second table you want the report to be made from.
            You can choose a Table or an existing Report.</p><br>
          <div class="form-row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Table Type:</label>
                <b-select required v-model="model.reportTableType2">
                  <b-select-option value="table">Table</b-select-option>
                  <b-select-option  value="report">Report</b-select-option>
                </b-select>
              </div>
            </div>
            <div class="col-md-6">
              <div v-if="model.reportTableType2 === 'table'" class="form-group">
                <label>Join Table:</label>
                <b-select required  v-model="model.reportJoinTable">
                  <b-select-option v-for="table in report.tables" :value="table.tableName" v-bind:key="table.tableName">{{table.tableName}}</b-select-option>
                </b-select>
              </div>
              <div v-else class="form-group">
                <label>Join Table:</label>
                <b-select required v-model="model.reportJoinTable">
                  <b-select-option v-for="table in report.reports" :value="table.reportName" v-bind:key="table.reportName">{{table.reportName}}</b-select-option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer"  @click="next('two')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'four'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next('five')">
          <strong>4. Report Join Contract</strong><br><br>
          <p style="line-height: 0;">Set up how you want the report to be joined.</p><br>
          <p style="line-height: 0;">Decide on which columns should be joined and the type of join.</p><br>
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Join Type:</label>
                <b-select required v-model="model.reportJoinType">
                  <b-select-option value="JOIN">JOIN</b-select-option>
                  <b-select-option  value="LEFT JOIN">LEFT JOIN</b-select-option>
                  <b-select-option  value="RIGHT JOIN">RIGHT JOIN</b-select-option>
                  <b-select-option  value="INNER JOIN">INNER JOIN</b-select-option>
                  <b-select-option  value="FULL JOIN">FULL JOIN</b-select-option>
                  <b-select-option  value="CROSS JOIN">CROSS JOIN</b-select-option>
                </b-select>
              </div>
              <p style="text-align: center;"><code v-if="report.columnsLoading">Columns Loading...</code></p>
              <code>Join Tables On</code>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{model.reportTable}} columns:</label>
                <b-select required v-model="model.columns1">
                  <b-select-option v-for="column in report.columns1.columns" :value="column.columnName" v-bind:key="column.columnName">{{column.columnName}}</b-select-option>
                </b-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{model.reportJoinTable}} columns:</label>
                <b-select required v-model="model.columns2">
                  <b-select-option v-for="column in report.columns2.columns" :value="column.columnName" v-bind:key="column.columnName">{{column.columnName}}</b-select-option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer"  @click="next(model.reportType==='join'?'three':'two')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'five'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="next('six')">
          <strong>5. Set up Report Conditions</strong><br><br>
          <p style="line-height: 0;">Decide the conditions for the columns of the Report.</p><br>
          <p style="line-height: 0;"><code>{{queriesMain}}</code></p><br>
          <p style="text-align: center;"><code v-if="report.columnsLoading">Columns Loading...</code></p>
          <div v-for="(query, index) in queries" v-bind:key="index" class="form-row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Column Name:</label>
                <multiselect
                    v-model="query.columnName"
                    :options="options"
                    placeholder="Column Name"
                    required
                    @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Query Type:</label>
                <multiselect
                    v-model="query.queryType  "
                    :options="options2"
                    placeholder="Query Type"
                    required
                    @change="formatCondition()"
                ></multiselect>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Keyword:</label>
                <b-input type="text" v-model="query.keyword" @change="formatCondition()"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="join">Join Type</label>
                <b-select
                    id="join"
                    :type="'text'"
                    v-model="query.join"
                    :label="'JOIN'"
                    :placeholder="'JOIN'"
                    required
                    class="form-control"
                    @change="addMoreQuery(query.join, index)"
                >
                  <option value="NONE">NONE</option>
                  <option value="OR">OR</option>
                  <option value="AND">AND</option>
                </b-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer" @click="next(model.reportType==='join'?'four':'two')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Next" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
        <form  v-if="screen ===  'six'" class="p-xl-2" style="padding: 10px;"  @submit.prevent="createReport()">
          <strong>6.  Set up Report Columns</strong><br><br>
          <p style="line-height: 0;">Decide the columns from the <code>Start Table</code> and <code>Join Table</code> that will feature in the Report.</p><br>
          <div class="form-row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Columns:</label>
                <br>
                <b-form-checkbox v-model="selectAll" switch size="lg">Select All Columns</b-form-checkbox>
                <br>
                <b-form-row v-if="!selectAll" >
                  <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selectedColumns"
                      name="flavour-2"
                      class="row"
                  >
                    <b-col cols="6" sm="4" md="3" v-for="option in options" v-bind:key="option">
                      <b-form-checkbox :value="option">{{ option }}</b-form-checkbox>
                    </b-col>
                  </b-form-checkbox-group>
                </b-form-row>
<!--                <span v-if="!selectAll">-->
<!--                      <b-form-checkbox-group-->
<!--                          v-model="selectedColumns"-->
<!--                          :options="options"-->
<!--                          value-field="item"-->
<!--                          text-field="name"-->
<!--                          disabled-field="notEnabled"-->
<!--                      ></b-form-checkbox-group>-->
<!--                  </span>-->
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <span style="width: 100%; cursor: pointer" @click="next('five')" class="btn btn-secondary btn-">Back</span>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <base-button title="Create Report" :loading="report.reportsLoading"/>
            </div>
          </div>
        </form>
      </div><!-- card -->
    </div>
  </div><!-- row -->
</template>

<script>
// import FlotChart from "../FlotChart";
import {mapState} from 'vuex'
import BaseButton from "../button/BaseButton";
export default {
  name: "TopCards",
  components:{
    BaseButton

  },
  data(){
    return{
      screen: 'one',
      options: [],
      selectAll: false,
      selectedColumns:[],
      options2: ["LIKE", "=", "<>", ">", "<", "<=", ">="],
      options3: ["NONE", "AND", "OR"],
      queries: [{ columnName: "", queryType: "", keyword: "", join: "" }],
      queriesMain: "",
      model:{
        reportColumn1:'',
        reportColumn2:'',
        reportTableType:'table',
        reportTableType2:'table',
        reportId: '',
        reportName: '',
        reportDescription: '',
        reportOwner: 'SYSTEM',
        reportBankCode: '000000',
        reportStatus: '',
        reportCategory: '',
        reportType: '',
        reportCreatedAt: '',
        reportFieldList: '',
        reportCondition: '',
        reportMaker: '',
        reportChecker: '',
        reportTable: '',
        requestType: 'create',
        reportUpdatedAt: '',
        reportJoinTable: '',
        reportJoinType: '',
        reportJoinOn: ''
      },
      limitEdit: false,
      passwordEdit: false,
      accountEdit: false,
    }
  },
  watch:{
    model: function () {
      console.log("hery")
      this.filterOutBadChar()
    }
  },
  methods:{
    async createReport(){
      console.log(this.selectedColumns.join(','))
      if (this.selectAll) this.model.reportFieldList = '';
      else this.model.reportFieldList = this.selectedColumns.join(',');
      this.model.reportCondition = this.queriesMain.trim();
      this.model.reportMaker = this.auth.userInfo.userId
      if (this.model.reportCondition === 'null  \'\'') this.model.reportCondition = ''
      if (this.model.reportCondition === 'null null \'\'') this.model.reportCondition = ''
      if (this.model.reportCondition === '\'\'') this.model.reportCondition = ''
      this.model.reportJoinOn = this.model.reportColumn1 + '=' + this.model.reportColumn2;
      await this.$store.dispatch("report/createReport", this.model, {root: true})
      await this.$store.dispatch("report/readReports", {reportBankCode:'000000', readAll:'YES', reportCategory:'General Service'}, {root: true})
      if (this.report.response.responseCode === '00'){
        this.model={
          reportColumn1:'',
              reportColumn2:'',
              reportTableType:'table',
              reportTableType2:'table',
              reportId: '',
              reportName: '',
              reportDescription: '',
              reportOwner: 'SYSTEM',
              reportBankCode: '000000',
              reportStatus: '',
              reportCategory: '',
              reportType: '',
              reportCreatedAt: '',
              reportFieldList: '',
              reportCondition: '',
              reportMaker: '',
              reportChecker: '',
              reportTable: '',
              requestType: 'create',
              reportUpdatedAt: '',
              reportJoinTable: '',
              reportJoinType: '',
              reportJoinOn: ''
        }
        this.queriesMain = ""
        this.screen = 'one'
      }
      //console.log(this.model)
    },
    addMoreQuery(_item, _index) {
      //this.formatCondition()
      let index = _index + 1;
      console.log("currentIndex", _index);
      console.log("nextIndex", index);
      console.log("length", this.queries.length);
      if (_item !== "NONE") {
        if (this.queries.length <= index) {
          this.queries.push({
            columnName: "",
            queryType: "",
            keyword: "",
            join: ""
          });
        }
      } else {
        this.queries.splice(index, this.queries.length - index);
      }
      this.formatCondition();
    },
    formatCondition() {
      let query = "";
      // eslint-disable-next-line no-unused-vars
      let join = "";

      for (let i = 0; i < this.queries.length; i++) {
        if (this.queries[i].queryType === "LIKE") {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
              query +
              this.queries[i].columnName +
              " " +
              this.queries[i].queryType +
              " '%" +
              this.queries[i].keyword +
              "%' " +
              join +
              " ";
          console.log("looping>>>>", query);
        } else {
          if (this.queries[i].join === "NONE") join = "";
          else join = this.queries[i].join;
          query =
              query +
              this.queries[i].columnName +
              " " +
              this.queries[i].queryType +
              " '" +
              this.queries[i].keyword +
              "' " +
              join +
              " ";
          // console.log("looping>>>>",query)
        }
      }
      this.queriesMain = query;
      // console.log("statements>>>>>>",query)
      // console.log("queries>>>>>>>>>",this.queries)
    },
    async next(_screen){
      console.log(_screen)
      this.screen = _screen;
      if (this.screen === 'four' || this.screen === 'five' || this.screen === 'six'){
        this.$store.dispatch('report/updateColumns2',{
          reportBankCode:"000000",
          reportName: this.model.reportJoinTable,
          requestType: 'readColumn'

        })
        await this.$store.dispatch('report/updateColumns1',{
          reportBankCode:"000000",
          reportName: this.model.reportTable,
          requestType: 'readColumn'
        })
        let columns = []
        let options = []
        console.log(this.report.columns1.columns)
        console.log(this.report.columns2.columns)
        if (this.report.columns2.columns != null)
          columns = columns.concat(this.report.columns1.columns,this.report.columns2.columns)
        else
          columns = this.report.columns1.columns
        console.log(columns)
        for (let i = 0; i < columns.length; i++) options[i] = columns[i].columnName
        console.log(this.report.columns1)
        console.log(this.report.columns2)
        this.options = options
        console.log("columns>>>>>>>>>",this.options)
      }
    },
    filterOutBadChar(){
      if (this.model.orgAuthorizationLevel.substr(0,1) === '0')
        this.model.orgAuthorizationLevel =this.model.orgAuthorizationLevel.substr(1,this.model.orgAuthorizationLevel.length)
      this.model.orgAuthorizationLevel = this.model.orgAuthorizationLevel.replace('.', '')
      if (this.model.orgAuthorizationLevel === '') this.model.orgAuthorizationLevel = 0;
      console.log(this.model.orgAuthorizationLevel)
    },
    async saveLimit(){
      this.model.orgId = localStorage.orgID;
      await this.$store.dispatch("company/setCompanyLimit", this.model, {root: true})
      this.limitEdit = !this.limitEdit
    },
    async savePassword(){
      if(this.minLength !== 'green'||
          this.maxLength  !== 'green'||
          this. hasLettersNumbers !== 'green' ||
          this.hasSpecialXters !== 'green' ||
          this.hasLowerCase !== 'green' ||
          this.hasUpperCase !== 'green' ||
          this.hasSpace !== 'green'
      ) return;
      this.model.orgId = localStorage.orgID;
      this.model.userId = this.system.userInfo.id;
      await  this.$store.dispatch("auth/changePassword", this.model, {root: true})
      this.model.oldPassword =  '';
      this.model.password =  '';
      this.model.repeatPassword =  '';
      this.passwordEdit = !this.passwordEdit
    },
    async saveAccounts(){
      this.model.orgId = localStorage.orgID;
      await this.$store.dispatch("account/saveAccounts", this.model, {root: true})
      this.accountEdit = !this.accountEdit
    }
  },
  computed:{
    ...mapState([
      'report',
      'system',
      'company',
      'account',
      'auth',
      'officer'
    ]),
    minLength:function(){
      return this.model.password.length >= 8?'green':'red'
    },
    maxLength:function(){
      return this.model.password.length <= 13?'green':'red'
    },
    hasLettersNumbers:function(){
      return ( /[a-zA-Z]/g.test(this.model.password) && /[0-9]/g.test(this.model.password))?'green':'red'
    },
    hasSpecialXters:function(){
      return /[^a-zA-Z0-9]/g.test(this.model.password)?'green':'red'
    },
    hasLowerCase:function(){
      return /[a-z]/g.test(this.model.password)?'green':'red'
    },
    hasUpperCase:function(){
      return /[A-Z]/g.test(this.model.password)?'green':'red'
    },
    hasSpace:function(){
      return !(/\s/g.test(this.model.password))?'green':'red'
    },
    accounts: function () {
      console.log(this.account.allAccounts.data);
      let acc = [];
      if (this.account.allAccounts.data != null)
        for (let i = 0; i < this.account.allAccounts.data.length; i++){
          acc.push(this.account.allAccounts.data[i].accountNumber)
        }
      return acc
    },
    modelAccounts:{
      get(){
        console.log(this.model.accounts);
        return this.model.accounts
      },
      set(val){
        this.model.accounts = val
      }
    }
  },
  mounted() {
    this.$store.dispatch('report/readReports', {
          reportBankCode: '00000',
          readAll: 'YES',
          reportCategory: 'GENERAL SERVICES'
        }
    )
    this.$store.dispatch('report/readTables', {reportBankCode: '00004'})
    // this.$store.dispatch('account/updateAllAccounts', {orgID: localStorage.orgID});
    // if (this.account.accounts.data != null)
    //     for (let i = 0; i < this.account.accounts.data.length; i++){
    //         this.model.accounts.push(this.account.accounts.data[i].accountNumber)
    //     }
    // this.model.orgTransactionLimit = this.company.company[0].orgTransactionLimit?this.company.company[0].orgTransactionLimit:0
    // this.model.orgAuthorizationLevel = this.company.company[0].orgAuthCount?this.company.company[0].orgAuthCount:0
  }
}
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .top-card {
    width: 103%;
  }
}
@media only screen and (min-width: 707px) and (max-width: 900px) {
  .top-card {
    width: 104%;
  }
}

@media only screen and (min-width: 517px)  and (max-width: 707px) {
  .top-card {
    width: 105%;
  }
}

@media only screen and (min-width: 451px)  and (max-width: 517px) {
  .top-card {
    width: 106%;
  }
}

@media only screen  and (max-width: 451px) {
  .top-card {
    width: 107%;
  }
}
</style>
