<template>
  <div class="content ht-100v pd-0">
    <div class="content-body pd-10">
      <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
        <div>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-style1 mg-b-10">
              <li class="breadcrumb-item">
                <router-link to="#">Transaction</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">All Transactions</li>
            </ol>
          </nav>
          <h4 class="mg-b-0 tx-spacing--1">View All Transactions</h4>
          <p class="tx-color-03 tx-12 mg-b-0">{{auth.userInfo.userFirstName}} {{auth.userInfo.userLastName}}</p>
          <p class="tx-color-03 tx-12 mg-b-0"><strong>Last login:</strong> {{auth.userInfo.userLastLogin | moment('dddd, MMMM Do YYYY, h:mm:ss a')}}</p>
        </div>
        <div class="d-none d-md-block">
          <download-excel
              :data="analytics"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadExcel')}}
          </download-excel>
          <download-excel
              type="csv"
              :data="analytics"
              class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
            <PrinterIcon class="mg-r-5"/>{{$t('extra.downloadCsv')}}
          </download-excel>
        </div>
      </div>
      <base-top-cards :carditems="cards"/>
      <hr>
      <loan-metric-chart/>
    </div>
  </div>
</template>

<script>
import BaseTopCards from "../../components/card/BaseTopCards";
import LoanMetricChart from "../../components/chart/LoanMetricChart";
import { PrinterIcon } from 'vue-feather-icons'
import {mapState} from 'vuex'

export default {
  name: 'Gateway',
  data() {
    return {
      fields: [
        { key: 'serial', label: 'S/N', sortable: true, sortDirection: 'desc' },
        { key: 'businessName', label: 'Business Name', sortable: true, sortDirection: 'desc' },
        { key: 'analyticType', label: 'Loan Type', sortable: true, class: 'text-left' },
        { key: 'analyticInterestRate', label: 'Interest Rate', sortable: true, class: 'text-right' },
        { key: 'analyticAmount', label: 'Amount', sortable: true, class: 'text-right' },
        { key: 'analyticPeriod', label: 'Period', sortable: true, sortDirection: 'desc', class: 'text-right' },
        { key: 'analyticRepaymentDay', label: 'Repayment Day', sortable: true, class: 'text-right' },
        // { key: 'lienAmount', label: 'Lien Amount', sortable: true, class: 'text-left' },
        { key: 'analyticApplicationDate', label: 'Application Date', sortable: true, class: 'text-left' },
        { key: 'analyticApprovalDate', label: 'Approval Date', sortable: true, class: 'text-left' },
        { key: 'analyticStatus', label: 'Status', sortable: true, class: 'text-left' },
        { key: 'actionsLoan', label: 'Action', sortable: true, class: 'text-center' }
      ],
    }
  },
  components: {
    LoanMetricChart,
    BaseTopCards,
    PrinterIcon,


  },
  computed: {
    cards:{
      get(){
        return [
          {
            id: 0,
            name: 'Total Transaction',
            number: this.dashboard.analytics.totalTransactions,// == null?'0.00':'GHS '+this.numberWithCommas(this.totalGhs),
            color: 'bg-primary'
          },
          {
            id: 1,
            name: 'Posted Transaction',
            number: this.dashboard.analytics.postedTransactions,// == null?'0.00':'GBP '+this.numberWithCommas(this.totalGbp),
            color: 'bg-success'
          },
          {
            id: 2,
            name: 'AML Gate',
            number: this.dashboard.analytics.pendingTransactions,// == null?'0.00':'USD '+this.numberWithCommas(this.totalUsd),
            color: 'bg-orange'
          },
          {
            id: 3,
            name: 'New Requests',
            number: this.dashboard.analytics.requestedTransactions,// == null?'0.00':'EUR '+this.numberWithCommas(this.totalEur),
            color: 'bg-black-8'
          }
        ]
      },
      set(){

      }
    },
    analytics(){
      return  this.dashboard.analytics.dataDc
    },
    analyticChartCp(){
      return  this.dashboard.analytics.dataCp
    },
    analyticChartDp(){
      return  this.dashboard.analytics.dataDc
    },
    authobj: function () {
      return this.$store.state.auth.response
    },
    ...mapState([
      'dashboard',
      'auth'
    ])
  },
  methods: {
    numberWithCommas(x) {
      let parts = x.toString().split(".");
      if (parts[1] == null)  parts[1] = '00'
      else if (parts[1].length === 0) parts[1] = parts[1]+'00'
      else if (parts[1].length === 1) parts[1] = parts[1]+'0'
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
  },
  created() {
    if (this.auth.userInfo.userType === "IMTO")
      this.$store.dispatch("dashboard/updateAnalytics", {mtoCode:this.auth.userInfo.mtoCode, _startDate:"", _endDate:""}, {root: false}).then()
    else
      this.$store.dispatch("dashboard/updateAnalytics", {_mtoCode:"", _startDate:"", _endDate:""}, {root: false}).then()
    // this.$store.dispatch("analytic/updateLoanCharts", {orgID: localStorage.orgID}, {root: false}).then()
  }
}


</script>

<style scoped>

</style>
